<template>
    <section>
        <section class="search-container">
            <a-form layout="inline" class="m-t-14">
                <a-form-item label="作业类型" >
                    <a-select class="search-select" placeholder="全部" v-model="searchInfo.type">
                        <a-select-option value="">全部</a-select-option>
                        <a-select-option v-for="item in typeList" :key="item.id" :value="item.id">{{item.name}}</a-select-option>
                    </a-select>
                </a-form-item>
                <a-form-item label="申请单位">
                    <a-input class="search-input" placeholder="请输入" v-model="searchInfo.applyCompany" />
                </a-form-item>
                <a-form-item label="申请人" >
                    <a-input class="search-input" placeholder="请输入" v-model="searchInfo.applyPerson" />
                </a-form-item>
                <a-form-item label="作业部位" >
                    <a-input class="search-input" placeholder="请输入" v-model="searchInfo.position" />
                </a-form-item>
                <a-form-item label="作业时间" >
                    <a-range-picker class="search-range-date" v-model="searchInfo.endDate" />
                </a-form-item>
                <a-form-item>
                    <a-button type="primary" class="m-r-8" @click="search"><a-icon type="search" />查询</a-button>
                    <a-button @click="reset"><a-icon type="reload" />重置</a-button>
                </a-form-item>
            </a-form>
            <a-row class="right-action-panel m-t-14">
                <a-button class="item" @click="exprotFile('safeJobExcel',{params:searchInfo,dateArray:['startDate','endDate']})"><a-icon type="export" />导出</a-button>
                <router-link class="item" to="/ssms/task/record/add"><a-button type="primary">添加</a-button></router-link>
                
                <!-- <a-radio-group class="item m-t-6" v-model="searchInfo.startDate" @change="e => changeTime(e.target.value)">
                    <a-radio :value="7">最近一周</a-radio>
                    <a-radio :value="30">最近一个月</a-radio>
                    <a-radio :value="90">最近三个月</a-radio>
                </a-radio-group> -->
            </a-row>
        </section>

        <section class="list-table-container m-t-18">
            <a-table size="small" :columns="columns" :data-source="recordLists" :pagination="{total,pageSize,hideOnSinglePage:true,showTotal:(total) => `共${total}条`,size:'default',showQuickJumper:true,showSizeChanger:false}" @change="changePageNum">
                 <span slot="endTime" slot-scope="text,list">{{list.startTime + '~' +list.endTime}}</span>
                 <span slot="status" slot-scope="text,list">{{list.status=='1'?'已监管':'未监管'}}</span>
                 <!-- 操作 -->
                <a slot="action" slot-scope="text,list">
                    <router-link class="m-r-8" :to="{path:'/ssms/task/record/details',query:{id:list.id,type:1}}">查看</router-link>
                    
                    <router-link class="m-r-8" :to="{path:'/ssms/task/record/edit',query:{id:list.id}}">编辑</router-link>

                    <router-link v-if="list.supervisePerson && list.supervisePerson.includes(userId) && list.myStatus != 1 && list.status != 1" class="m-r-8" :to="{path:'/ssms/task/record/details',query:{id:list.id,type:2}}">监管</router-link>
                   
                    <a-popconfirm title="确定删除?" @confirm="deleteItem(list.id)">删除</a-popconfirm>
                </a>
            </a-table>
        </section>
    </section>
</template>


<script>
const columns = [
    {title:'序号',dataIndex:'key',align:'center',key:'key',width:80},
    {title:'作业类型',dataIndex:'typeName',align:'center',key:'typeName'},
    {title:'申请单位',dataIndex:'applyCompany',align:'center',key:'applyCompany'},
    {title:'申请人',dataIndex:'applyPerson',align:'center',key:'applyPerson'},
    {title:'监管状态',dataIndex:'applyPerson',align:'center',key:'status',scopedSlots:{customRender: "status"}},
    {title:'作业部位',dataIndex:'position',align:'center',key:'position',width:200,ellipsis:true},
    {title:'作业描述',dataIndex:'description',align:'center',key:'description',width:300,ellipsis:true},
    {title:'作业时间',dataIndex:'endTime',align:'center',key:'endTime',scopedSlots:{customRender: "endTime"}},
    {title:'操作',scopedSlots:{customRender: "action"},align:'center'},
]
import {exprotFile} from '@/until/unit'
export default {
    data(){
        return{
            columns,
            total:0,
            pageNum:1,
            pageSize:9,
            recordLists:[],
            searchInfo:{
                endDate:[]
            },
            typeList:[], //作业类型下拉列表
        }
    },
     beforeCreate(){
        this.form = this.$form.createForm(this)
    },
    created(){
        this.querySafeJobTypeList()
        this.querySafeJobList()
    },
    computed:{
        userId(){
            return this.$store.state.userInfo.userId
        }
    },
    methods:{
        exprotFile,
        //作业类型下拉列表
        querySafeJobTypeList(){
            this.$api.querySafeJobTypeList().then(res =>{
                if(res.code === 200){
                    this.typeList = (res.data && res.data.records) || []
                }
            })
        },
        //查询安全作业列表
        querySafeJobList(){
            let params = {...this.searchInfo}
            params.current = this.pageNum
            params.size = this.pageSize
            params.startDate = this.searchInfo.endDate.length ? this.searchInfo.endDate[0].format('YYYY-MM-DD') : ''
            params.endDate = this.searchInfo.endDate.length ? this.searchInfo.endDate[1].format('YYYY-MM-DD') : ''
            this.$api.querySafeJobList(params).then(res =>{
                if(res.code === 200){
                    this.recordLists = (res.data && res.data.records) || []
                    this.total = (res.data && res.data.total) || 0
                    this.recordLists.forEach((item,ind) =>{
                        let typeItem = this.typeList.filter(v => v.id == item.type)[0]
                        item.key = (this.pageSize*(this.pageNum - 1))+ind+1
                        item.typeName = typeItem ? typeItem.name : ''
                    })
                }
            })
        },
        //重置
        reset(){
            this.searchInfo = {
                endDate:[]
            }
            this.pageNum = 1
            this.querySafeJobList()
        },
        search(){
            this.pageNum = 1
            this.querySafeJobList()
        },
        //分页
        changePageNum(p){
            this.pageNum = p.current
            this.querySafeJobList()
        },
        //删除
        deleteItem(id){
            this.$api.deleteSafeJob(id).then(res =>{
                if(res.code === 200){
                    this.querySafeJobList()
                }
            })
        }
    }
}
</script>